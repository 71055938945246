//================================================ lazyload対応
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';


//================================================ jQuery
import $ from 'jquery'


//====================================== バグ対処

window.setTimeout(loadHead, 1000);
function loadHead() {
  document.body.classList.remove('preload');
  $('.header__hover__firstNone').removeClass('header__hover__firstNone');
}

//================================================ 下からふわっと

function scroll_fadein() {
  const windowHeight = $(window).height();
  const scroll = $(window).scrollTop();

  let buffer;
  if ($(window).width() > 768) {
    buffer = 300;
  } else {
    buffer = 100;
  }

  $('.fadeInUp').each(function () {
    const targetPosition = $(this).offset().top;
    if (scroll > targetPosition - windowHeight + buffer) {
      $(this).addClass("showed");
    }
  });
  $('.show').each(function () {
    const targetPosition = $(this).offset().top;
    if (scroll > targetPosition - windowHeight + buffer) {
      $(this).addClass("showed");
    }
  });
}


$(window).on('scroll', function() {
  // const windowHeight = $(window).height();
  // const scroll = $(window).scrollTop();

  // let buffer;
  // if ($(window).width() > 768) {
  //   buffer = 300;
  // } else {
  //   buffer = 100;
  // }

  // $('.fadeInUp').each(function () {
  //   const targetPosition = $(this).offset().top;
  //   if (scroll > targetPosition - windowHeight + buffer) {
  //     $(this).addClass("showed");
  //   }
  // });
  // $('.show').each(function () {
  //   const targetPosition = $(this).offset().top;
  //   if (scroll > targetPosition - windowHeight + buffer) {
  //     $(this).addClass("showed");
  //   }
  // });
  scroll_fadein();
});

scroll_fadein();


//================================================ ハンバーガーメニュー
//メニューの開閉
if($('.burgerBtn').length > 0) {    

  $('.burgerBtn').on('click', function () {
    $('.bMenu').addClass('is-open');
    $('.bMenuBk').addClass('is-open');
  //ハンバーガーメニューが出ている時は後ろはスクロールしない
    $('html').addClass('is-fixd');
  });

  $('.bMenu__head').on('click', function () {
    $('.bMenu').removeClass('is-open');
    $('.bMenuBk').removeClass('is-open');
    //ハンバーガーメニューが出ている時は後ろはスクロールしない
    $('html').removeClass('is-fixd');
  });
  //ハンバーガーメニューの後ろの黒背景
  $('.bMenuBk').on('click', function () {
    $('.bMenu').removeClass('is-open');
    $('.bMenuBk').removeClass('is-open');
    $('html').removeClass('is-fixd');
  });

  //スマホメニューの開閉
  $('.bMenu__item' + '.nextOpen').on('click', function () {
    // $(this).next().toggleClass('is-open');
    $(this).next().slideToggle(200);
  });
  //黒の下矢印の向きがクリックしたら上向きに
  $('.bMenu__item' + '.bottomBlackYazirusi').on('click', function () {
    $(this).toggleClass('ueYazirusi');
  });
  //白のの下矢印の向きがクリックしたら上向きに
  $('.bMenu__item' + '.bottomWhiteYazirusi').on('click', function () {
    $(this).toggleClass('ueYazirusi');
  });

}

//================================================ 会社概要のスマホ時メニュー
$('.companyTabArea-sp__dt').on('click', function () {
  $(this).toggleClass('is-open');
  $('.companyTabArea-sp__dd').toggleClass('is-open');
});

//================================================ ページ内スクロール


var pagetop = $('.page-top');   
pagetop.hide();
$(window).on('scroll', function () {
    if ($(this).scrollTop() > 100) {  //100pxスクロールしたら表示
        pagetop.fadeIn();
    } else {
        pagetop.fadeOut();
    }
});



  $('a[href^="#"]').on('click', function(e){
    // 移動先の調整　例えば「100」にすると100ピクセルずらして移動します　マイナスの値も可能
    // var adjust = 0;
    // スクロールの速度（ミリ秒）
    var headerHight = $('.header').height();

    var speed = 700;
    // リンク先を取得してhrefという変数に代入
    var href= $(this).attr("href");
    // リンク先にidがある場合ターゲットとして値を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // ターゲットの位置を取得し、調整がある場合は位置の調整を行う
    var position = target.offset().top - headerHight;
    // スクロール実行
    $.when(
      $("html, body").animate({
        scrollTop: position
      }, speed, "swing"),
      e.preventDefault(),
    ).done(function() {
      var diff = target.offset().top - headerHight;
      if (diff === position) {
      } else {
        $("html, body").animate({
          scrollTop: diff
        }, 10, "swing");
      }
    });

    console.log('スクロールクリック！');
    return false;
  });



//================================================ スマホ時アンカーリンクボタンの開閉
$('.underScrollSp__dt').on('click', function () {
  $(this).next().toggleClass('is-open');
  $(this).toggleClass('rotate');
});


//================================================ お知らせ一覧


/* ここには、表示するリストの数を指定します。 */
var moreNum = 20;

/* 表示するリストの数以降のリストを隠しておきます。 */
$('.newsList__item:nth-child(n + ' + (moreNum + 1) + ')').addClass('is-hidden');

/* 全てのリストを表示したら「もっとみる」ボタンをフェードアウトします。 */
$('.newsList__more').on('click', function() {
  $('.newsList__item.is-hidden').slice(0, moreNum).removeClass('is-hidden');
  if ($('.newsList__item.is-hidden').length == 0) {
    $('.newsList__more').fadeOut();
  } 
});

/* リストの数が、表示するリストの数以下だった場合、「もっとみる」ボタンを非表示にします。 */
var list = $(".newsList__item").length;  
if (list < moreNum) {
  $('.newsList__more').addClass('is-btn-hidden');
}


//================================================ 教室一覧とレンタル教室


$('.schoolList__conts__leadTitle').on('click', function(){
  $(this).next('.schoolList__conts_lists').slideToggle(200);

  if( $(this).hasClass('yazirushi__return') ) {
    $(this).removeClass('yazirushi__return');
  } else {
    $(this).addClass('yazirushi__return');
  }
});


//================================================ 教室検索エリア ※22.07.12追加


// const schoolSerchOnly2 = document.querySelector('.rentalSerach');
// const schoolCourseOnly = document.querySelector('.school__course');

// if(!schoolSerchOnly2 && schoolCourseOnly) {
//   console.log('教室検索のjsが走るページ');
//   const elemSecond = document.getElementById('sources-second');
//   const elemThird = document.getElementById('sources-third');
  
//   function schoolSerchBtn(elem, addClass) {
//     let loadChangEvent = () => {
//       let result = elem.value;
//       if(!result) {
//         console.log('選択肢のvalueが空だった場合');
//         document.querySelector('.btn__serch-red').classList.add(addClass + '__none');
//       } else {
//         console.log('選択肢のvalueに値があった場合');
//         document.querySelector('.btn__serch-red').classList.remove(addClass + '__none');
//       }
//     };
  
//     window.addEventListener('DOMContentLoaded', function() {
//       console.log('ロード後に実行');
//       loadChangEvent();
//     });
//     elem.addEventListener('change', function() {
//       console.log('選択時に実行');
//       loadChangEvent();
//     });
//   };
//   schoolSerchBtn(elemSecond, 'second');
//   schoolSerchBtn(elemThird, 'third');
// } else {
//   console.log('教室検索のjsが走らないページ');
// }



